@import '~bootstrap/scss/bootstrap';

.billing_select_label {
  display: flex;
  justify-content: start;
  label {
    width: 62%;
    margin: 0.5rem;
  }
  > div {
    width: 100%;
  }
}

.errorMessage {
  display: block;
  text-align: right;
  color: red;
  font-size: small;
}

.required::after {
  content: "*";
  color: red;
}