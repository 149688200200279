@import '~bootstrap/scss/bootstrap';

.tag {
  width: auto;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $secondary;
  padding: 0 8px;
  font-size: 14px;
  list-style: none;
  border: solid 1px $secondary;
  border-radius: 6px;
  margin: 0 8px 8px 0;
}

.tag_close {
  display: block;
  width: 16px;
  height: 16px;
  line-height: 16px;
  text-align: center;
  font-size: 14px;
  margin-left: 8px;
  color: $secondary;
  border-radius: 50%;
  cursor: pointer;
}
