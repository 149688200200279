.nav_link:not(._) {
  padding: 1rem;
  color: #212529;
  opacity: 0.6;
  &:hover {
    opacity: 1;
    color: #212529;
  }
}

.role_name {
  font-size: 90%;
  color: grey;
}