/*　スクロールバーの実装 */
@mixin day_color($color: black){
  color: $color;
  background-color: lightgray;
}


.calender_table {
  th {
    border-style: solid;
    border-color: black;
    border-width: 1px;
    padding: 0 7px 0 7px;
    @include day_color();
  }

  td {
    text-align: center;
    border-style: solid;
    border-width: 1px;
    border-color:black;
  }

  th:nth-child(8), th:nth-child(9) {
    background-color: #d0abab;
  }
}

.supplement {
  span {
    font-size: 0.875em;
    white-space: pre-wrap;
    display: block;
  }
}

