* {
  margin: 0;
}
body {
  background-color: #f5f6fa;
}
.App {
  text-align: center;
  display: flex;
  justify-content: space-evenly;
  background-color: #f5f6fa;
  padding: 20px;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

.app__dropdown {
  background-color: white;
}

.app__header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  justify-content: space-between;
}

.app__header > h1 {
  color: #fc3c3c;
  font-size: 2rem;
}

.app__stats {
  display: flex;
  justify-content: space-between;
}

.app__left {
  flex: 0.9;
}

.app__information > h3 {
  color: #6a5d5d;
  font-weight: 400;
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.app__information > h3:last-of-type {
  margin-top: 1rem;
}
.app__right {
  display: flex;
  flex-direction: column;
}

.app__right .MuiCardContent-root {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.app__graph {
  flex-grow: 1;
}

.app__graphTitle {
  margin-top: 20px;
  margin-bottom: 20px;
}

@media (max-width: 990px) {
  .app {
    flex-direction: column;
  }
}