.loading-spinner {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  transition: opacity 0.65s;
  pointer-events: auto;
  opacity: 1;
  z-index: 10000;
}
.loading-spinner.mask {
  position: absolute;
  background: rgba(255, 255, 255, 0.5);
  width: 100%;
  height: 100vh;
}
.loading-spinner.spinner {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
