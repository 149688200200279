.table {
  table-layout: fixed;
  tr {
    padding: 0.125rem;

    th {
      width: 7rem;
      padding-right: 0.375em;
      vertical-align: top;
      overflow-wrap: break-word;
    }
    td {
      padding-left: 0.375rem;
      vertical-align: top;
      overflow-wrap: break-word;
    }
  }
}
