/*　スクロールバーの実装 */
.table_sticky {
  display: block;
}
.table_sticky_th {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 1;
}
.table_stick_question_th {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 2;
}

@mixin table_scroll($tableWidth, $tableHeight: 50vh) {
  overflow: scroll;
    width: calc(100% - 1rem);
    max-width: calc(100% - 1rem);
    min-width: calc(100% - 1rem);
    height: $tableHeight;
    min-height: $tableHeight;
    max-height: $tableHeight;
  table {
    @if type-of($tableWidth) == "number" {
      width: $tableWidth;
      max-width: $tableWidth;
      min-width: $tableWidth;
    } @else {
      table-layout: fixed;
    }

    thead > tr {
      position: sticky;
      top: 0;
      z-index: 1;
      background-color: lightcyan;
    }

    tbody > tr {
      border-bottom: 1px solid black;

      td > table > thead > tr {
        z-index: 0;
      }
    }
  }
}
.oemApplyListTable {
  @include table_scroll(3500px);
}

.oemEventMessageListTable {
  @include table_scroll("");
}

.oemMonitorListTable {
  @include table_scroll("", 60vh);
}

.fiveText {
  width: 90px;
}
.tenText {
  width: 155px;
}
.twentyText {
  width: 290px;
}
.thirtyText {
  width: 410px;
}
.oemTableDateTime {
  width: 160px;
}
.surveyDate {
  width: 320px;
}
