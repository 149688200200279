.wrap_layout {
  position: relative;
  display: flex;
  height: 100vh;
}
.wrapper {
  flex-grow: 1;
  transition: all 300ms 0s ease;
  min-height: 100vh;
  width: 100%;
}
.main {
  background-color: #f8f9fa;
}
.main_inner {
  min-height: calc(100vh - 3.5rem - 1px - 2.5rem - 1px - 1.125rem - 1rem - 1px);
  max-height: calc(100vh - 3.5rem - 1px - 2.5rem - 1px - 1.125rem - 1rem - 1px);
  padding: 1.5rem 0;
  overflow-y: auto;
  overflow-x: hidden;
}
