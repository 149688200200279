//bootstrapカラー変数をimport
@import '~bootstrap/scss/bootstrap';

// モーダル対応のため
$zindex-right-bar: $zindex-popover - 1;

.correction {
  min-height: calc(100vh - 3.5rem - 1px - 2.5rem - 1px - 1.125rem - 1rem - 3rem - 1px);
  max-height: calc(100vh - 3.5rem - 1px - 2.5rem - 1px - 1.125rem - 1rem - 3rem - 1px);
}
.correction_nav {
  font-size: 0.875rem;
}
.correction_stepWrap {
  margin-left: 1.5rem;
}
.correction_stepTitle {
  display: block;
  font-size: 0.75rem;
  margin-top: 0.75rem;
  margin-bottom: 0.5rem;
}
.correction_stepList {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin: 0 0 0.75rem;
  padding: 0;
  font-size: 0.75rem;
}

.correction_stepListItem {
  list-style: none;
  & > * {
    color: $text-muted;
    text-decoration: none;
  }
  &:hover {
    & > * {
      color: $text-muted;
      text-decoration: none;
    }
  }
}
.correction_stepListItem_ckecked {
  & > * {
    color: $body-color;
  }
  &:hover {
    & > * {
      color: $body-color;
    }
  }
}
.correction_stepCheckRelease {
  font-size: 0.875rem;
}
.correction_manualAssign {
  font-size: 0.875rem;
}
.correction_formWrap {
  flex-shrink: 1;
  min-width: calc(50% - 8rem);
  margin-left: -0.75rem;
}
.correction_form {
  margin-left: 0.5rem;
}
.correction_formCol {
  min-height: calc(100vh - 3.5rem - 1px - 2.5rem - 1px - 1.125rem - 1rem - 3rem - 1px);
  max-height: calc(100vh - 3.5rem - 1px - 2.5rem - 1px - 1.125rem - 1rem - 3rem - 1px);
  overflow-y: auto;
  -ms-overflow-style: none;
  // scrollbar-width: none;

  // &::-webkit-scrollbar {
  //   // display: none;
  // }
}
.correction_contents {
  flex-grow: 0;
  flex-shrink: 1;
  min-width: calc(50% + 8rem);
  margin-left: 1.5rem;
  margin-right: -0.75rem;
  margin-top: -1.5rem;
  margin-bottom: -1.5rem;
  transition: all 0.3s ease-in-out 0s;
}
.correction_contentsCol {
  flex-shrink: 1;
  min-width: 0;
  overflow-y: auto;
  min-height: calc(100vh - 3.5rem - 1px - 2.5rem - 1px - 1.125rem - 1rem - 1px);
  max-height: calc(100vh - 3.5rem - 1px - 2.5rem - 1px - 1.125rem - 1rem - 1px);
  -ms-overflow-style: none;
  scrollbar-width: none;
  z-index: $zindex-right-bar; // モーダル対応

  &::-webkit-scrollbar {
    display: none;
  }
}
.correction_correction_contentsColFirst {
  flex-grow: 0;
  //min-width: calc(50% - 8rem);
  min-width: 22rem;
}
.correction_accordionWrap {
  max-width: 1.25rem;
  top: -1.5rem;
  right: -1.5rem;
  margin: 0;
  padding: 0;
  z-index: $zindex-right-bar + 1; // モーダル対応
}
.correction_accordionArrow {
  width: 1.25rem;
  height: 2rem;
  cursor: pointer;
}
.correction_accordion {
  display: none;

  &:checked {
    & + .correction_contents {
      width: 0;
      min-width: 0;
      flex-basis: 0;
      flex-grow: 0;
      flex-shrink: 1;
      padding: 0;
    }
  }
}
.correction_accordionBody {
  font-size: 0.875rem;
  padding: 0 0 0.75rem 0;
}
.correction_accordionBtn {
  & > button {
    padding-right: 0;
    padding-left: 0;
  }
}
.correction_table {
  th {
    font-weight: normal;
    color: $text-muted;
  }
  td {
    word-break: break-all;
  }
}
.correction_appinfo {
  font-weight: normal;
  h5 {
    font-size: 0.875rem;
    color: $gray-500;
  }
  p {
    font-size: 1rem;
  }
}

// .btn_red {
//   background-color: $red-600;
//   border-color: $red-600;
//   color: white;
//   &:hover,
//   &:focus {
//     background-color: $red-800;
//     border-color: $red-800;
//     color: white;
//   }
// }
// .btn_gray {
//   background-color: $gray-400;
//   border-color: $gray-400;
//   color: black;
//   &:hover,
//   &:focus {
//     background-color: $gray-600;
//     border-color: $gray-600;
//     color: white;
//   }
// }
.editor_ng {
  background-color: $warning;
  border-color: $danger;
  // & span,
  // pre {
  //   color: black;
  // }
}
.editor_escale {
  background-color: $info;
  // border-color: $cyan-400;
  // & span,
  // pre {
  //   color: black;
  // }
}
.toggle_green:hover {
  background-color: $green-100;
  color: $green-500;
}
.toggle_red:hover {
  background-color: $red-100;
  color: $red-500;
}
.toggle_selected {
  pointer-events: none;
}
.btn_edit {
  color: $gray-700;
  &:hover {
    color: $gray-900;
  }
}
.btn_delete {
  color: $red-600;
  &:hover {
    color: $red-800;
  }
}

.check_point_card_header {
  padding: 4px 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  > div {
    font-size: 14px;
    padding-left: 5px;
  }
}
.card_header_gray {
  background-color: rgba(0, 0, 0, 0.05);
}
.monitor_rule_image {
  max-width: 40%;
  height: auto;
}

.survey_proof_monitor_rule {
  cursor: pointer;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
}

.switch_display_survey_proof_monitor_rule {
  display: none;

  &:checked {
    & + .survey_proof_monitor_rule {
      display: block;
    }
  }
}

.correction_history {
  th {
    font-size: 0.5rem;
  }
  td {
    font-size: 0.4rem;
  }
}

.correction_history_date {
  td {
    font-size: 0.2rem;
  }
}

.correction_monitor_rule_img {
  img {
    width: 50%;
  }
  [class$="missionBox"] {
    margin: -8rem 0;
    [class$="sampleImg"] {
      margin-bottom: -10rem;
      > a {
        text-decoration: none;
      }
    }
    [class$="mission_command"] {
      margin-bottom: -3rem;
    }
  }
}