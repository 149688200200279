@import '~bootstrap/scss/bootstrap';

html,
body {
  height: 100vh;
}
h3,
h4 {
  margin-bottom: 1.5rem;
}
h5 {
  margin-bottom: 0.75rem;
}
h6 {
  font-weight: bold;
}
button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  padding: 0;
  appearance: none;
}

/* パンくず */
.breadcrumb {
  margin-bottom: 0;
  a {
    color: #212529;
    opacity: 0.6;
    &:hover {
      opacity: 1;
    }
  }
}
/* サイドバー */
.sidebar {
  width: 17rem;
  background-color: #333d4d;
  flex-grow: 0;
  flex-shrink: 0;
  min-height: 100vh;
  max-height: 100vh;
  overflow-x: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
  transition: all 300ms 0s ease;
  &::-webkit-scrollbar {
    display: none;
    -webkit-appearance: none;
  }
}
.sidebar_item {
  display: flex;
  align-items: center;
  color: rgba(255, 255, 255, 0.5);
  text-decoration: none;
  padding: 0.75rem 1.5rem;
  &:hover {
    color: rgba(255, 255, 255, 0.8);
    path {
      fill: rgba(255, 255, 255, 0.8);
    }
  }
  &.active {
    color: rgba(255, 255, 255, 0.8);
    background-color: rgba(255, 255, 255, 0.05);
    path {
      fill: rgba(255, 255, 255, 0.8);
    }
  }
  svg {
    margin-right: 0.5rem;
  }
}
.accordionInSidebar.accordion-item {
  border: none;
  .accordion-button {
    align-items: center;
    padding: 0.75rem 1.5rem;
    line-height: 1.5;
    color: rgba(255, 255, 255, 0.5);
    background-color: transparent;
    box-shadow: none;
    &:after {
      filter: brightness(1000%);
      opacity: 0.5;
      width: 1rem;
      margin-left: 0.25rem;
      background-size: contain;
      background-position: center;
    }
    &:hover {
      color: rgba(255, 255, 255, 0.8);
      background-color: rgba(255, 255, 255, 0.05);
    }
    svg {
      margin-right: 0.5rem;
    }
  }
  .accordion-body {
    color: rgba(255, 255, 255, 0.5);
    .list-group-item {
      display: flex;
      align-items: center;
      color: rgba(255, 255, 255, 0.5);
      background-color: transparent;
      border: none;
      padding: 0.75rem 1.5rem 0.75rem 3.25rem;
      &.active {
        color: rgba(255, 255, 255, 0.8);
        background-color: rgba(255, 255, 255, 0.05);
      }
      &:hover {
        color: rgba(255, 255, 255, 0.8);
        background-color: rgba(255, 255, 255, 0.05);
      }
      svg {
        margin-right: 0.5rem;
      }
    }
  }
}
/* サイドバー開閉トグルメニュー */
.sidebar_toggle {
  position: absolute;
  left: 17rem;
  z-index: 1000;
  transition: all 300ms 0s ease;
  > label {
    cursor: pointer;
  }
}
.sidebar_toggleInput {
  transition: all 300ms 0s ease;
  &:checked {
    + .sidebar {
      margin-left: -17rem;
      + .sidebar_toggle {
        left: 0;
      }
    }
  }
}

/* Cardコンポーネント */
.card-body {
  padding: 1.5rem;
}

/* ボタン */
.btn-link {
  text-decoration: none;
}

/* アコーディオン */
.accordion-item {
  background-color: transparent;
}
.accordion-button {
  opacity: 1;
  background-color: transparent;
  box-shadow: none;
  &:not(.collapsed) {
    background-color: transparent;
    color: $gray-900;
    opacity: 1;
    box-shadow: none;
  }
  &:after {
    filter: brightness(0);
  }
}

/*多重モーダルバックドロップ調整*/
@for $i from 1 through 6 {
  $zIndexBackdrop: #{1000 + (5 * $i)};
  $zIndexContent: #{1000 + (5 * $i) + 2};
  .modal-backdrop.show:nth-of-type(#{$i}) {
    z-index: $zIndexBackdrop;
  }
  div[role='dialog'][aria-modal='true']:nth-of-type(#{$i}) {
    z-index: $zIndexContent;
  }
}
