.wrap_logo {
  height: 3.5rem;
}
.logo {
  height: 150%;
  opacity: 0.8;
}
.sidebar {
  width: 17rem;
  background-color: #333d4d;
  flex-grow: 0;
  flex-shrink: 0;
  min-height: 100vh;
  max-height: 100vh;
  overflow-x: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
  transition: all 300ms 0s ease;
  &::-webkit-scrollbar {
    display: none;
    -webkit-appearance: none;
  }
}

.sidebar_title {
  font-weight: bold;
  padding: 1rem 1.5rem 0.5rem;
  margin-bottom: 0;
  opacity: 0.5;
  font-size: 0.75rem;
}
